<template>
  <b-card-code title="" no-body>
    <b-card-body
      ><h3>Total Unverify Pendidikan : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Cari" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Search"
              v-model="search"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Status" label-for="status">
            <v-select
              v-model="selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              :options="statusList"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Tahun Masuk" label-for="tahunMasuk">
            <b-form-input
              id="tahunMasuk"
              placeholder="1950"
              type="number"
              v-model="angkatan"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Fakultas" label-for="tahunMasuk">
            <v-select
              v-model="selectedFakultas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="nama_fakultas"
              :options="fakultass"
            />
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Jurusans" label-for="tahunMasuk">
            <v-select
              v-model="selectedJurusans"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="nama_jurusan"
              :options="jurusans"
            />
          </b-form-group>
        </b-col>

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="selectAllRows"
          >
            Select All
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="clearSelected"
          >
            Clear Selected
          </b-button>
          <!-- dropdown -->
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            right
            text="Action"
            variant="outline-primary"
          >
            <b-dropdown-item @click="validateVerifyPendidikan()"
              >Verify All Selected</b-dropdown-item
            >
            <b-dropdown-item @click="validateUnverifyPendidikan()"
              >Unverify All Selected</b-dropdown-item
            >
          </b-dropdown>
        </b-button-group>

        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="validateExport"
          >
            <feather-icon icon="DownloadCloudIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="showDetailPendidikan(data.item)">
            <feather-icon icon="FileIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>

          <b-dropdown-item @click="verifySingle(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Verify</span>
          </b-dropdown-item>
          <b-dropdown-item @click="unverifySingle(data.item)">
            <feather-icon icon="XSquareIcon" size="16" />
            <span class="align-middle ml-50">Unverify</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <b-modal id="modal-detail">
      <template #modal-title> Detail Pendidikan </template>
      <!-- Invoice Description: Total -->
      <table style="width: 100%">
        <tr>
          <td>Nama</td>
          <td>:</td>
          <td>{{ detailPendidikan.name }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>:</td>
          <td>{{ detailPendidikan.email }}</td>
        </tr>
        <tr>
          <td>NPM</td>
          <td>:</td>
          <td>{{ detailPendidikan.npm }}</td>
        </tr>
        <tr>
          <td>NIK</td>
          <td>:</td>
          <td>{{ detailPendidikan.nik }}</td>
        </tr>
        <tr>
          <td>Jenis Kelamin</td>
          <td>:</td>
          <td>{{ detailPendidikan.gender }}</td>
        </tr>
        <tr>
          <td>Tanggal Lahir</td>
          <td>:</td>
          <td>{{ detailPendidikan.tanggal_lahir }}</td>
        </tr>
        <tr>
          <td>No. Telp</td>
          <td>:</td>
          <td>{{ detailPendidikan.no_telp }}</td>
        </tr>
        <tr>
          <td>Domisili</td>
          <td>:</td>
          <td>{{ detailPendidikan.address }}</td>
        </tr>

        <tr>
          <td>Foto</td>
          <td>:</td>
          <td>
            <img
              :src="detailPendidikan.photo"
              alt=""
              width="100"
              height="100"
            />
          </td>
        </tr>
      </table>
    </b-modal>
    <b-modal id="modal-unverify" hide-footer>
      <template #modal-title> Input Alasan Unverify </template>
      <div class="d-block text-center">
        <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p>
      </div>
      <b-form-group label="Type" label-for="type">
        <v-select
          v-model="selectedType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="typeList"
        />
      </b-form-group>

      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        :disabled="selectedType == null"
        @click="unverifyPendidikan(4)"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ['multi', 'single', 'range'],
      fields: [{ key: 'name', label: 'Nama' }, 'email', 'universitas', 'fakultas', 'jurusan', 'tahun_masuk', 'tahun_keluar', 'document', 'emailed_at', 'created_at', 'status', 'action'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        0: 'Register', 1: 'Verify', 2: "Can't find", 3: 'Input wrong data'
      },
      {
        0: 'light-primary', 1: 'light-success', 2: 'light-warning', 3: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],

      selectedJurusans: [],
      selectedFakultas: [],
      fakultass: [],
      jurusans: [],
      search: null,
      angkatan: null,
      email: null,
      descUnverify: null,
      detailPendidikan: {},
      typeList: [
        { id: 2, name: "Can't find" },
        { id: 3, name: 'Input wrong data' }
      ],
      selectedType: { id: 2, name: "Can't find" },
      statusList: [
        { id: 0, name: 'Register' },
        { id: 1, name: 'Verify' },
        { id: 2, name: "Can't find" },
        { id: 3, name: 'Input wrong data' },
      ],

      selectedStatus: [{ id: 0, name: 'Register' }],

    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getPendidikan()
      }
      else {
        this.currentPage = 1
      }

    },
    async getPendidikan() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        var fakultasIds = this.selectedFakultas.length > 0 ? this.selectedFakultas.map(function (item) { return item['id'] }) : null;
        var jurusanIds = this.selectedJurusans.length > 0 ? this.selectedJurusans.map(function (item) { return item['id'] }) : null;
        var statusIds = this.selectedStatus.length > 0 ? this.selectedStatus.map(function (item) { return item['id'] }) : null;

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          angkatan: this.angkatan,
          fakultas: fakultasIds ? fakultasIds.toString() : null,
          jurusan: jurusanIds ? jurusanIds.toString() : null,
          status: statusIds ? statusIds : null

        }
        const { data } = await API.pendidikan.list(form)
        this.data_table = data
        this.items = data.data
        this.rows = data.total

        this.handleMsgSuccess('Berhasil tampilkan data pendidikan')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async getFakultas() {

      try {
        const { data } = await API.master.fakultas()
        this.fakultass = data.data

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
    },
    async getJurusans() {

      try {
        const { data } = await API.master.jurusan()
        this.jurusans = data.data
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
    },
    validateExport() {
      if (this.rows > 500) {
        this.$bvModal.show("modal-export")
      }
      else {
        this.exportData()
      }


    },
    async exportData() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      this.$bvModal.hide("modal-export")
      var fakultasIds = this.selectedFakultas.length > 0 ? this.selectedFakultas.map(function (item) { return item['id'] }) : null;
      var jurusanIds = this.selectedJurusans.length > 0 ? this.selectedJurusans.map(function (item) { return item['id'] }) : null;
      try {
        var param = {
          destination_email: this.email,
          total: this.rows,
          search: this.search,
          angkatan: this.angkatan,
          fakultas: fakultasIds ? fakultasIds.toString() : null,
          jurusan: jurusanIds ? jurusanIds.toString() : null,
        }
        const { data } = await API.pendidikan.export(param)
        if (data.is_delay) {
          this.sweetAlertSuccess('Export sedang diproses, silahkan cek email secara berkala!')
        }
        else {
          window.open(data.url, '_blank');
        }

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)

      }
    },
    validateVerifyPendidikan() {

      this.$swal({
        title: 'Warning!',
        text: ' Apakah anda yakin?',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.dismiss == 'cancel') {
          console.log('cancel');
        } else {
          this.verifyPendidikan()
        }

      })

    },
    validateUnverifyPendidikan() {
      // this.$swal({
      //   title: 'Warning!',
      //   text: ' Apakah anda yakin?',
      //   icon: 'warning',
      //   showCancelButton: true,
      //   allowOutsideClick: true,
      //   customClass: {
      //     confirmButton: 'btn btn-primary',
      //     cancelButton: 'btn btn-outline-danger ml-1',
      //   },
      //   buttonsStyling: false,
      // }).then((result) => {
      //   if (result.dismiss == 'cancel') {
      //     console.log('cancel');
      //   } else {
      //     this.unverifyPendidikan()
      //   }

      // })
      // this.detailPendidikan = item
      this.$bvModal.show("modal-unverify")
      //this.$bvModal.show("modal-unverify")

    },
    async verifyPendidikan() {

      if (this.selected.length == 0) {
        this.toastError('Pilih pendidikan terlebih dahulu!')

      } else {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        var ids = this.selected.length > 0 ? this.selected.map(function (item) { return item['id'] }) : null;
        try {
          var body = {
            ids: ids
          }
          const { data } = await API.pendidikan.verify(body)
          this.sweetAlertSuccess('Berhasil verifikasi pendidikan!')
          this.refreshPage()

        } catch (error) {
          console.log(error)
          this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
        }
        finally {
          this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)

        }

      }

    },
    async unverifyPendidikan() {

      if (this.selected.length == 0) {
        this.toastError('Pilih pendidikan terlebih dahulu!')

      } else {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        var ids = this.selected.length > 0 ? this.selected.map(function (item) { return item['id'] }) : null;
        try {
          var body = {
            ids: ids,
            description: this.descUnverify,
            status: this.selectedType.id
          }

          const { data } = await API.pendidikan.unverify(body)
          this.sweetAlertSuccess('Berhasil unverifikasi pendidikan!')
          this.refreshPage()

        } catch (error) {
          console.log(error)
          this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
        }
        finally {
          this.$bvModal.hide("modal-unverify")


        }

      }

    },

    verifySingle(data) {
      this.clearSelected()
      this.selected = [data]
      this.verifyPendidikan()
    },
    unverifySingle(data) {
      this.clearSelected()
      this.selected = [data]
      this.validateUnverifyPendidikan()
    },
    showDetailPendidikan(item) {
      this.detailPendidikan = item
      console.log(this.detailPendidikan)
      this.$bvModal.show("modal-detail")

    }



  },
  mounted() {
    this.getPendidikan()
    this.getFakultas()
    this.getJurusans()
  },
  watch: {
    currentPage() {
      this.getPendidikan()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
